import {initSwiper} from "../inits/_swipers.init.js";
import {
  initBackgroundGridAnimation,
  initCardImageAnimation,
  initElementReveal, initFooterC2aAnimation, initGalleryAnimation,
  initGradientTextRevealLines,
  initStaggeredChildrenReveal,
  initTextReveal, refreshAllScrollTriggers
} from "../inits/_animatons.init.js";

document.addEventListener('DOMContentLoaded', () => {
  initSwiper();

  (() => {
    initGradientTextRevealLines()
    initTextReveal();
    initElementReveal()
    initStaggeredChildrenReveal()
    initCardImageAnimation()
    initGalleryAnimation();
    initBackgroundGridAnimation()
    initFooterC2aAnimation();
    refreshAllScrollTriggers();
  })()

  window.addEventListener('resize', () => {
    refreshAllScrollTriggers();
  },{passive: true});

},{passive: true});
