import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SplitType from "split-type";
import {parseCubicBezier} from "../helpers/parseCubicBezier.js";

gsap.registerPlugin(ScrollTrigger);

const markers = false,
  CUBIC_BEZIER = parseCubicBezier('cubic-bezier(.19, 1, .22, 1)'),
  CUBIC_BEZIER_DURATION = .75,
  VISIBLE_IMAGE_PATH = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  HIDDEN_GALLERY_IMAGE_CLIP_PATH = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
  CLOSED_GALLERY_IMAGE_CLIP_PATH = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';


export function refreshAllScrollTriggers() {
  ScrollTrigger.getAll().forEach(trigger => trigger.refresh());
}

export function initTextReveal() {
  const ALL_TEXT_REVEALS = document.querySelectorAll('[data-animation="text-reveal"]');

  ALL_TEXT_REVEALS.forEach(REVEAL_TEXT_ELEMENT => {
    const REVEAL_LINES = new SplitType(REVEAL_TEXT_ELEMENT, {
      types: 'lines, words', tagName: 'span'
    }), WORDS = REVEAL_LINES.words;

    gsap.from(WORDS, {
      yPercent: 100, stagger: 0.05, ease: CUBIC_BEZIER, duration: CUBIC_BEZIER_DURATION, scrollTrigger: {
        trigger: REVEAL_TEXT_ELEMENT, start: "top 75%", end: 'bottom top', markers: markers
      }
    });
  })
}

export function initGradientTextRevealLines() {

  const ALL_LINE_REVEALS = document.querySelectorAll('[data-animation="gradient-text-lines-reveal"]');

  ALL_LINE_REVEALS.forEach(REVEAL_TEXT_ELEMENT => {

    const PARENT_LINES = new SplitType(REVEAL_TEXT_ELEMENT, {
      types: 'lines,', tagName: 'span', lineClass: 'parent-line'
    });

    const CHILD_LINES = new SplitType(PARENT_LINES.lines, {
      types: 'lines,', tagName: 'span', lineClass: 'child-line'
    });

    const ANIMATION_LINES = CHILD_LINES.lines;


    gsap.from(ANIMATION_LINES, {
      yPercent: 100, stagger: 0.05, ease: CUBIC_BEZIER, duration: CUBIC_BEZIER_DURATION, scrollTrigger: {
        trigger: REVEAL_TEXT_ELEMENT, start: "top 75%", end: 'bottom top', markers: markers
      }
    });
  })
}

export function initElementReveal() {
  const ALL_ELEMENT_REVEALS = document.querySelectorAll('[data-animation="element-reveal"]'),
    REVEAL_OFFSET = 120;

  ALL_ELEMENT_REVEALS.forEach(ELEMENT_REVEAL => {
    const SCROLL_TRIGGER_OPTIONS = {
      trigger: ELEMENT_REVEAL, start: "top 75%", end: 'bottom 5%', markers: markers
    }

    gsap.fromTo(ELEMENT_REVEAL, {
      translateY: REVEAL_OFFSET, opacity: 0,
    }, {
      translateY: 0,
      opacity: 1,
      ease: CUBIC_BEZIER,
      duration: CUBIC_BEZIER_DURATION,
      scrollTrigger: SCROLL_TRIGGER_OPTIONS
    });

  })
}

export function initStaggeredChildrenReveal() {
  const ALL_REVEAL_ROWS = document.querySelectorAll('[data-animation="staggered-children"]'),
    REVEAL_OFFSET = 120;

  ALL_REVEAL_ROWS.forEach(REVEAL_ROW => {


    const SCROLL_TRIGGER_OPTIONS = {
        trigger: REVEAL_ROW, start: "top 75%", end: 'bottom 5%', markers: markers
      },
      TRIGGER_ELEMENTS = REVEAL_ROW.querySelectorAll('[data-animated="child"]'),
      TRIGGER_ELEMENT_PICTURES = REVEAL_ROW.querySelectorAll('[data-animated="child"] picture'),
      TRIGGER_ELEMENT_IMAGES = REVEAL_ROW.querySelectorAll('[data-animated="child"] img');

    gsap.timeline({scrollTrigger: SCROLL_TRIGGER_OPTIONS})
      .fromTo(TRIGGER_ELEMENTS, {
        translateY: REVEAL_OFFSET, opacity: 0,
      }, {
        translateY: 0,
        opacity: 1,
        stagger: .25,
        ease: CUBIC_BEZIER,
        duration: CUBIC_BEZIER_DURATION,
      }, "=")
      .fromTo(TRIGGER_ELEMENT_PICTURES, {
        clipPath: HIDDEN_GALLERY_IMAGE_CLIP_PATH
      }, {
        clipPath: VISIBLE_IMAGE_PATH,
        stagger: .25,
        ease: CUBIC_BEZIER,
        duration: CUBIC_BEZIER_DURATION,
      }, "=")
      .from(TRIGGER_ELEMENT_IMAGES, {
        scale: 1.3,
        stagger: .25,
        ease: CUBIC_BEZIER,
        duration: CUBIC_BEZIER_DURATION,
      }, "=");

  })
}

export function initCardImageAnimation() {
  const ALL_CARD_IMAGE_REVEALS = document.querySelectorAll('[data-animation="card-image-reveal"]')
  ALL_CARD_IMAGE_REVEALS.forEach(CARD_IMAGE_REVEAL => {
    const CARD_REVEAL_PICTURE = CARD_IMAGE_REVEAL.querySelector('.card-img-holder'), SCROLL_TRIGGER_OPTIONS = {
      trigger: CARD_IMAGE_REVEAL, start: "top 75%", end: 'bottom 25%', markers: markers
    };
    if (!CARD_REVEAL_PICTURE) return;

    const CARD_REVEAL_IMAGE = CARD_REVEAL_PICTURE.querySelector('img');

    gsap.fromTo(CARD_REVEAL_PICTURE, {
      clipPath: HIDDEN_GALLERY_IMAGE_CLIP_PATH,
    }, {
      clipPath: VISIBLE_IMAGE_PATH,
      stagger: 0.05,
      ease: CUBIC_BEZIER,
      duration: CUBIC_BEZIER_DURATION,
      scrollTrigger: SCROLL_TRIGGER_OPTIONS
    })

    gsap.from(CARD_REVEAL_IMAGE, {
      scale: 1.3,
      stagger: 0.05,
      ease: CUBIC_BEZIER,
      duration: CUBIC_BEZIER_DURATION,
      scrollTrigger: SCROLL_TRIGGER_OPTIONS
    });
  });
}

export function initMapAnimation() {
  const MAP_ROOT = document.querySelector('[data-sticky-map="root"]'),
    MAP_CONTENT = document.querySelector('[data-sticky-map="content"]'),
    MAP = document.querySelector('[data-sticky-map="map"]');

  if (!MAP_ROOT || !MAP_CONTENT || !MAP) return;

  const GRADIENT_GROUPS = MAP.querySelectorAll('[id*="gradient_group"]'),
    TILE_GROUPS = MAP.querySelectorAll('[id*="tile_group"]'),
    GRAY_TILES = MAP.querySelectorAll('[id*="tile_group"] [id*="gray_"]'),
    SHADOW_TILE_GROUPS = MAP.querySelectorAll('[id*="shadow_group"]'),
    GROUP_OFFSET_Y = -16,
    TILE_FLOAT_Y = 6,

    LOOP_ANIMATION_TIMELINE = gsap.timeline({paused: true})
      .fromTo(TILE_GROUPS, {translateY: 0},
        {
          translateY: TILE_FLOAT_Y,
          ease: 'Sine.easeInOut',
          duration: .5,
          stagger: {
            each: 0.025,
            yoyo: true,
            repeat: -1
          }
        }
      )

  const MARKER_BREAKPOINT_SM = 576,
    MARKER_BREAKPOINT_MD = 768,
    MARKER_BREAKPOINT_LG = 992,
    MARKER_BREAKPOINT_XL = 1200,
    MARKER_BREAKPOINT_XXL = 1400;

  gsap.matchMedia().add({
      desktopSizeXs: `(max-width: ${MARKER_BREAKPOINT_SM - 1}px)`,
      desktopSizeSm: `(min-width: ${MARKER_BREAKPOINT_SM}px) and (max-width: ${MARKER_BREAKPOINT_MD - 1}px)`,
      desktopSizeMd: `(min-width: ${MARKER_BREAKPOINT_MD}px) and (max-width: ${MARKER_BREAKPOINT_LG - 1}px)`,
      desktopSizeLg: `(min-width: ${MARKER_BREAKPOINT_LG}px) and (max-width: ${MARKER_BREAKPOINT_XL - 1}px)`,
      desktopSizeXl: `(min-width: ${MARKER_BREAKPOINT_XL}px) and (max-width: ${MARKER_BREAKPOINT_XXL - 1}px)`,
      desktopSizeXxl: `(min-width: ${MARKER_BREAKPOINT_XXL}px)`
    }, (context) => {
      const {
        desktopSizeXs,
        desktopSizeSm,
        desktopSizeMd,
        desktopSizeLg,
        desktopSizeXl,
        desktopSizeXxl
      } = context.conditions;

      /*ALLE CASES MÜSSEN VERGEBEN SEIN UM ZU FUNKTIONIEREN!*/
      let MAP_TILES_START = "top bottom";
      if (desktopSizeXs) MAP_TILES_START = "top bottom"
      if (desktopSizeSm) MAP_TILES_START = "top bottom"
      if (desktopSizeMd) MAP_TILES_START = "top bottom";
      if (desktopSizeLg) MAP_TILES_START = "top bottom";
      if (desktopSizeXl) MAP_TILES_START = "top bottom";
      if (desktopSizeXxl) MAP_TILES_START = "top bottom";

      /*ALLE CASES MÜSSEN VERGEBEN SEIN UM ZU FUNKTIONIEREN!*/
      let MAP_TILES_END = "top bottom";
      if (desktopSizeXs) MAP_TILES_END = "top bottom";
      if (desktopSizeSm) MAP_TILES_END = "top 80%";
      if (desktopSizeMd) MAP_TILES_END = "top 70%";
      if (desktopSizeLg) MAP_TILES_END = "top 60%";
      if (desktopSizeXl) MAP_TILES_END = "top 60%";
      if (desktopSizeXxl) MAP_TILES_END = "top 60%";

      gsap.timeline({
        stagger: 0.05,
        ease: CUBIC_BEZIER,
        duration: CUBIC_BEZIER_DURATION,

        scrollTrigger: {
          trigger: MAP_CONTENT,
          scrub: .5,
          start: MAP_TILES_START,
          end: MAP_TILES_END,

          onLeave: () => LOOP_ANIMATION_TIMELINE.restart(),

          onEnterBack: () => {
            LOOP_ANIMATION_TIMELINE.pause();

            gsap.to(TILE_GROUPS, {
              translateY: 0,
              duration: .25,
              stagger: 0,
              ease: "sine.inOut",
            })
          },

        }
      }).from(SHADOW_TILE_GROUPS, {opacity: 0}, 0)
        .to(GRADIENT_GROUPS, {translateY: GROUP_OFFSET_Y}, 0)
        .to(GRAY_TILES, {opacity: 0}, 0);

      /*ALLE CASES MÜSSEN VERGEBEN SEIN UM ZU FUNKTIONIEREN!*/
      let MAP_REVEAL_START = "top bottom";
      if (desktopSizeXs) MAP_REVEAL_START = "top bottom";
      if (desktopSizeSm) MAP_REVEAL_START = "top bottom";
      if (desktopSizeMd) MAP_REVEAL_START = "top bottom";
      if (desktopSizeLg) MAP_REVEAL_START = "top bottom";
      if (desktopSizeXl) MAP_REVEAL_START = "top bottom";
      if (desktopSizeXxl) MAP_REVEAL_START = "top bottom";

      /*ALLE CASES MÜSSEN VERGEBEN SEIN UM ZU FUN KTIONIEREN!*/
      let MAP_REVEAL_END = "top top";
      if (desktopSizeXs) MAP_REVEAL_END = "top top";
      if (desktopSizeSm) MAP_REVEAL_END = "top top";
      if (desktopSizeMd) MAP_REVEAL_END = "top top";
      if (desktopSizeLg) MAP_REVEAL_END = "top top";
      if (desktopSizeXl) MAP_REVEAL_END = "top top";
      if (desktopSizeXxl) MAP_REVEAL_END = "top top";

      gsap.from(MAP, {
          ease: CUBIC_BEZIER,
          duration: CUBIC_BEZIER_DURATION,
          translateY: 500,
          scrollTrigger: {
            trigger: MAP_ROOT,
            scrub: true,
            start: MAP_REVEAL_START,
            end: MAP_REVEAL_END,
            markers: markers
          }
        }
      )
    }
  )
}

export function initBackgroundGridAnimation() {
  const BACKGROUND_GRIDS = document.querySelectorAll('[data-animation="grid"]');
  BACKGROUND_GRIDS.forEach(GRID => {
    const GSAP_FIND_HELPER = gsap.utils.selector(GRID),
      VISIBLE_ITEM_SELECTOR = '[data-animated="true"]',
      HIDDEN_ITEM_SELECTOR = '[data-animated="false"]',
      ITEMS_TO_ANIMATE = GRID.getAttribute('data-animate-at-once') || 2,

      GET_GRID_ITEMS_TO_SHOW = (selector, animateCount) => {
        return gsap.utils.shuffle(GSAP_FIND_HELPER(selector)).slice(0, animateCount);
      },

      SHOW_ITEMS_TIMELINE = gsap.timeline({
        scrollTrigger: {
          trigger: GRID,
          start: "top 75%",
          markers: markers,


          onEnter: function () {
            const HIDDEN_ITEMS_TO_SHOW = GET_GRID_ITEMS_TO_SHOW(HIDDEN_ITEM_SELECTOR, ITEMS_TO_ANIMATE),
              VISIBLE_ITEMS_TO_HIDE = GET_GRID_ITEMS_TO_SHOW(VISIBLE_ITEM_SELECTOR, ITEMS_TO_ANIMATE);

            startVisible(HIDDEN_ITEMS_TO_SHOW);


            startHide(VISIBLE_ITEMS_TO_HIDE);
          }


        },
      }),
      HIDE_ITEMS_TIMELINE = gsap.timeline({
        scrollTrigger: {
          trigger: GRID,
          start: "top 75%",
          markers: markers,
          onEnter: function () {
            const VISIBLE_ITEMS_TO_HIDE = GET_GRID_ITEMS_TO_SHOW(VISIBLE_ITEM_SELECTOR, ITEMS_TO_ANIMATE);
            startVisible(VISIBLE_ITEMS_TO_HIDE);
          }
        },
      });

    function startVisible(itemsToAnimate) {


      SHOW_ITEMS_TIMELINE.to(itemsToAnimate, {
        backgroundPosition: "100% 100%",
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER,
        stagger: gsap.utils.random(1, 3),

        onStart: function () {
          this.targets().map(item => item.setAttribute('data-animated', 'running-visible'));
        },

        onComplete() {
          this.targets().map(item => item.setAttribute('data-animated', 'true'));

          const HIDDEN_ITEMS_TO_SHOW = GET_GRID_ITEMS_TO_SHOW(HIDDEN_ITEM_SELECTOR, ITEMS_TO_ANIMATE);
          startVisible(HIDDEN_ITEMS_TO_SHOW);
        }
      })
    }

    function startHide(itemsToAnimate) {
      HIDE_ITEMS_TIMELINE.to(itemsToAnimate, {
        backgroundPosition: "200% 200%",
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER,
        stagger: gsap.utils.random(1, 3),
        onStart: function () {
          this.targets().map(item => item.setAttribute('data-animated', 'running-hide'));
        },
        onComplete() {
          this.targets().map(item => item.setAttribute('data-animated', 'false'));

          const VISIBLE_ITEMS_TO_HIDE = GET_GRID_ITEMS_TO_SHOW(VISIBLE_ITEM_SELECTOR, ITEMS_TO_ANIMATE);
          startHide(VISIBLE_ITEMS_TO_HIDE);
        }
      })
    }
  })
}

export function initGalleryAnimation() {
  const GALLERIES = document.querySelectorAll('[data-animation="gallery"]');

  GALLERIES.forEach(GALLERY => {
    const GALLERY_FINDER = gsap.utils.selector(GALLERY),
      HIDDEN_SELECTOR = '[data-animated="hidden"]',
      VISIBLE_SELECTOR = '[data-animated="visible"]',
      ANIMATE_ITEMS = 2;

    function GET_GRID_ITEMS_TO_SHOW(selector) {
      return gsap.utils.shuffle(GALLERY_FINDER(selector)).slice(0, ANIMATE_ITEMS);
    }

    function GET_SHOW_ITEMS_TIMELINE() {
      const ITEMS_TO_SHOW = GET_GRID_ITEMS_TO_SHOW(HIDDEN_SELECTOR),
        SHOW_STAGGER = gsap.utils.random(.5, .875),
        SHOW_TIMELINE = gsap.timeline({
          delay: 2
        });

      let hiddenItemsImages = [];
      ITEMS_TO_SHOW.forEach(item => hiddenItemsImages.push(item.querySelector('img')));

      SHOW_TIMELINE
        .to(ITEMS_TO_SHOW, {
          clipPath: VISIBLE_IMAGE_PATH,
          ease: CUBIC_BEZIER,
          stagger: SHOW_STAGGER,
          duration: CUBIC_BEZIER_DURATION,
          onStart: function () {
            this.targets().map(item => item.setAttribute('data-animated', 'running'));
          },
          onComplete: function () {
            setTimeout(() => {
              this.targets().map(item => item.setAttribute('data-animated', 'visible'));
            }, 500);
          }
        })
        .fromTo(hiddenItemsImages, {
          scale: 1.3
        }, {
          scale: 1,
          ease: CUBIC_BEZIER,
          stagger: SHOW_STAGGER,
          duration: CUBIC_BEZIER_DURATION,
        }, "<")

      return SHOW_TIMELINE;
    }

    function GET_HIDE_ITEMS_TIMELINE() {
      const ITEMS_TO_HIDE = GET_GRID_ITEMS_TO_SHOW(VISIBLE_SELECTOR),
        HIDE_STAGGER = gsap.utils.random(.5, .875),

        HIDE_TIMELINE = gsap.timeline({duration: CUBIC_BEZIER_DURATION});

      let visibleItemsImages = [];
      ITEMS_TO_HIDE.forEach(item => visibleItemsImages.push(item.querySelector('img')));

      HIDE_TIMELINE
        .fromTo(ITEMS_TO_HIDE, {
          clipPath: VISIBLE_IMAGE_PATH
        }, {
          clipPath: CLOSED_GALLERY_IMAGE_CLIP_PATH,
          duration: CUBIC_BEZIER_DURATION,
          ease: CUBIC_BEZIER,
          stagger: HIDE_STAGGER,
          onStart: function () {
            this.targets().map(item => item.setAttribute('data-animated', 'running'));
          },
        })
        .to(ITEMS_TO_HIDE, {
          clipPath: HIDDEN_GALLERY_IMAGE_CLIP_PATH,
          duration: 0,
          onComplete: function () {
            this.targets().map(item => item.setAttribute('data-animated', 'hidden'));
          }
        }, ">")

      return HIDE_TIMELINE;
    }

    const SHOW_ITEMS_TIMELINE = gsap.timeline({
      scrollTrigger: {
        trigger: GALLERY,
        start: "top 75%",
        markers: markers,
        onLeave: function () {
          SHOW_ITEMS_TIMELINE.pause();
        },
        onLeaveBack: function () {
          SHOW_ITEMS_TIMELINE.pause();
        },
        onEnterBack: function () {
          SHOW_ITEMS_TIMELINE.resume();
        }
      },
      onComplete: function () {
        SHOW_ITEMS_TIMELINE.add(GET_SHOW_ITEMS_TIMELINE());
        SHOW_ITEMS_TIMELINE.add(GET_HIDE_ITEMS_TIMELINE());
      }
    })

    SHOW_ITEMS_TIMELINE.add(GET_SHOW_ITEMS_TIMELINE());
    SHOW_ITEMS_TIMELINE.add(GET_HIDE_ITEMS_TIMELINE());
  })
}

export function initFooterC2aAnimation() {
  const C2A_ELEMENT = document.querySelector('[data-animation="c2a"]');
  if (!C2A_ELEMENT) return;

  const GSAP_FIND_HELPER = gsap.utils.selector(C2A_ELEMENT),
    C2A_IMAGES = GSAP_FIND_HELPER('.image-grid-item'),
    FOOTER_C2A_TIMELINE = gsap.timeline({
        scrollTrigger: {
          trigger: C2A_ELEMENT,
          start: "top bottom",
          markers: markers,
          onEnter: function () {
            startFooterAnimation()
          },
          onLeave: function () {
            FOOTER_C2A_TIMELINE.pause();
          },
          onLeaveBack: function () {
            FOOTER_C2A_TIMELINE.pause();
          },
          onEnterBack: function () {
            FOOTER_C2A_TIMELINE.resume();
          }
        },
        onComplete: function () {
          startFooterAnimation();
        }
      }
    );

  function startFooterAnimation() {
    const HIDE_OPTIONS_OBJECT = {
        delay: gsap.utils.random(1, 3),
        clipPath: CLOSED_GALLERY_IMAGE_CLIP_PATH,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      },
      SHOW_OPTIONS_OBJECT = {
        clipPath: VISIBLE_IMAGE_PATH,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      },
      RESET_OPTIONS_OBJECT = {
        clipPath: HIDDEN_GALLERY_IMAGE_CLIP_PATH,
        duration: 0
      };

    FOOTER_C2A_TIMELINE
      .to(C2A_IMAGES[5], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[5], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[5].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[2], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[2].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[0], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[0], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[0].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[3], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[3].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[7], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[7], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[7].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[8], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[8].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[2], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[2], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[2].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[1], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[1].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[8], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[8], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[8].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[7], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[7].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[1], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[1], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[1].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[5], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[5].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")

      .to(C2A_IMAGES[3], {...HIDE_OPTIONS_OBJECT}, ">")
      .to(C2A_IMAGES[3], {...RESET_OPTIONS_OBJECT}, ">")
      .set(C2A_IMAGES[3].querySelector('img'), {
        scale: 1,
        duration: 0,
      }, "<")
      .to(C2A_IMAGES[0], {...SHOW_OPTIONS_OBJECT}, "<")
      .fromTo(C2A_IMAGES[0].querySelector('img'), {
        scale: 1.3
      }, {
        scale: 1,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER
      }, "<")
  }

}

export function initDetailContactBoxAnimation() {
  const CONTACT_BOX = document.querySelector('.detail-contact-box');
  if (!CONTACT_BOX) return;

  const BOX_FINDER = gsap.utils.selector(CONTACT_BOX),
    HIDDEN_SELECTOR = '[data-animated="hidden"]',
    VISIBLE_SELECTOR = '[data-animated="visible"]',
    ANIMATE_ITEMS = 2;

  function GET_GRID_ITEMS_TO_SHOW(selector) {
    return gsap.utils.shuffle(BOX_FINDER(selector)).slice(0, ANIMATE_ITEMS);
  }

  function GET_SHOW_ITEMS_TIMELINE() {
    const ITEMS_TO_SHOW = GET_GRID_ITEMS_TO_SHOW(HIDDEN_SELECTOR),
      SHOW_STAGGER = gsap.utils.random(.5, .875),
      SHOW_TIMELINE = gsap.timeline({
        delay: 2
      });


    let hiddenItemsImages = [];
    ITEMS_TO_SHOW.forEach(item => hiddenItemsImages.push(item.querySelector('img')));

    SHOW_TIMELINE
      .to(ITEMS_TO_SHOW, {
        clipPath: VISIBLE_IMAGE_PATH,
        ease: CUBIC_BEZIER,
        stagger: SHOW_STAGGER,
        duration: CUBIC_BEZIER_DURATION,
        onStart: function () {
          this.targets().map(item => item.setAttribute('data-animated', 'running'));
        },
        onComplete: function () {
          setTimeout(() => {
            this.targets().map(item => item.setAttribute('data-animated', 'visible'));
          }, 500);
        }
      })
      .fromTo(hiddenItemsImages, {
        scale: 1.3
      }, {
        scale: 1,
        ease: CUBIC_BEZIER,
        stagger: SHOW_STAGGER,
        duration: CUBIC_BEZIER_DURATION,
      }, "<")

    return SHOW_TIMELINE;
  }

  function GET_HIDE_ITEMS_TIMELINE() {
    const ITEMS_TO_HIDE = GET_GRID_ITEMS_TO_SHOW(VISIBLE_SELECTOR),
      HIDE_STAGGER = gsap.utils.random(.5, .875),

      HIDE_TIMELINE = gsap.timeline({duration: CUBIC_BEZIER_DURATION});

    let visibleItemsImages = [];
    ITEMS_TO_HIDE.forEach(item => visibleItemsImages.push(item.querySelector('img')));

    HIDE_TIMELINE
      .fromTo(ITEMS_TO_HIDE, {
        clipPath: VISIBLE_IMAGE_PATH
      }, {
        clipPath: CLOSED_GALLERY_IMAGE_CLIP_PATH,
        duration: CUBIC_BEZIER_DURATION,
        ease: CUBIC_BEZIER,
        stagger: HIDE_STAGGER,
        onStart: function () {
          this.targets().map(item => item.setAttribute('data-animated', 'running'));
        },
      })
      .to(ITEMS_TO_HIDE, {
        clipPath: HIDDEN_GALLERY_IMAGE_CLIP_PATH,
        duration: 0,
        onComplete: function () {
          this.targets().map(item => item.setAttribute('data-animated', 'hidden'));
        }
      }, ">")

    return HIDE_TIMELINE;
  }

  const SHOW_ITEMS_TIMELINE = gsap.timeline({
    onComplete: function () {
      SHOW_ITEMS_TIMELINE.add(GET_SHOW_ITEMS_TIMELINE());
      SHOW_ITEMS_TIMELINE.add(GET_HIDE_ITEMS_TIMELINE());
    }
  })

  SHOW_ITEMS_TIMELINE.add(GET_SHOW_ITEMS_TIMELINE());
  SHOW_ITEMS_TIMELINE.add(GET_HIDE_ITEMS_TIMELINE());
}
